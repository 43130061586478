<template>
  <main-container>
    <v-row>
      <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
      <v-col md="8" sm="12">
        <v-card outlined>
          <v-card-title>
            Lessons & Blessings
          </v-card-title>
          <v-card-text
            class="text-left black--text"
            v-html="$store.state.lessonsBlessings.header"
          >
          </v-card-text>

          <v-card-text
            class="black--text"
            v-html="$store.state.lessonsBlessings.poem"
          ></v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
    </v-row>
  </main-container>
</template>

<script>
import MainContainer from "../components/MainContainer.vue";
export default {
  components: { MainContainer },
};
</script>
